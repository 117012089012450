import React from "react";
import { useLocation } from "react-router-dom";

export default function JobPostDetails({}) {
  const location = useLocation();
  const job = location.state;

  console.log(job); // This will log the job object you passed

  return (
    <div className="flex flex-col justify-center w-full h-auto items-center bg-gradient-to-b from-indigo-800 via-blue-500 to-blue-300">
      <div className="flex flex-col rounded-2xl w-4/5 h-auto m-4 p-4 bg-white/10 backdrop-blur-lg shadow-lg">
        <div className="flex flex-row relative justify-start items-center p-2 w-full h-[35%]  rounded-2xl">
          <p className="text-white text-4xl z-40 font-bold font-varela absolute bottom-[5%] left-[5%]">
            {job.title}
          </p>

          <img
            src="./images/5426596.jpg"
            className="hidden md:flex w-full h-[300px] object-cover relative brightness-75 rounded-md"
          ></img>
          <img
            src="./images/5338397.jpg"
            className="flex md:hidden w-full h-[300px] object-cover relative brightness-75 rounded-md"
          ></img>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-5  gap-4 p-2 w-full h-auto my-2">
          <div className="flex flex-col lg:col-span-2 lg:row-span-1 w-[100%] p-2 md:p-4 h-auto rounded-md">
            <p className="text-white font-bold font-varela">Nice To Have</p>
            <ul className="list-disc text-white font-varela text-left px-4 text-sm sm:text-md">
              {job.niceHaving.map((item, index) => (
                <li key={index} className="m-2 text-sm font-varela">
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col lg:col-span-3 lg:row-span-2 p-2  md:p-4 h-auto bg-[#9C27B0] rounded-md">
            <p className="text-white font-bold font-varela">Responsibilities</p>
            <ul className="list-disc text-white font-varela text-left px-4 text-sm sm:text-md">
              {job.responsibilites.map((item, index) => (
                <li key={index} className="m-2 text-sm font-varela">
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col lg:col-span-3 lg:row-span-1 p-2 md:p-4 bg-[#32CD32] rounded-md">
            <p className="text-white font-bold font-varela">Requirements</p>
            <ul className="list-disc text-white font-varela text-left px-4 text-sm sm:text-md">
              {job.requirements.map((item, index) => (
                <li key={index} className="m-2 text-sm font-varela">
                  {item}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col lg:col-span-2 lg:row-span-2 p-2 md:p-4 bg-[#FF6347] rounded-md">
            <p className="text-white font-bold font-varela">
              What You Will Gain
            </p>
            <ul className="list-disc text-white font-varela text-left px-4 text-sm sm:text-md">
              {job.youGain.map((item, index) => (
                <li key={index} className="m-2 text-sm font-varela">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex flex-col justify-center space-y-2 items-center">
          <a href = "https://forms.gle/qP8aiciHAhuQjmYa7" class="px-10 py-4 rounded-full font-bold font-varela cursor-pointer border-0 bg-white shadow-md text-sm uppercase tracking-wider transition-all duration-500 ease-in-out hover:tracking-widest hover:bg-[#FFC107] hover:text-white  active:bg-[#FFEB3B] active:shadow-none active:translate-y-2 active:transition-[100ms]">
            Apply
          </a>

          <p className="text-white font-varela">
            {job.alternateApply}{" "}
            <span>
              <a
                className="text-blue-600 font-bold cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
                href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@techmertia.com"
              >
                contact@techmertia.com
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
