import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Testimonials = () => {
  useEffect(() => {
    gsap.utils.toArray(".employee-div").forEach((element) => {
      gsap.fromTo(
        element,
        {
          scale: 2,
          filter: "blur(10px)",
        },
        {
          scale: 1,
          filter: "blur(0px)",
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: element,
            start: "top 80%",
            end: "top 40%",
            scrub: true,
          },
        }
      );
    });
  }, []);

  return (
    <section className="relative bg-gradient-to-b from-blue-300 via-blue-500 to-indigo-800 py-16 overflow-hidden">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute top-0 left-0 w-full h-full text-blue-700 opacity-30"
          viewBox="0 0 1440 600"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 200C150 250 300 150 450 200C600 250 750 150 900 200C1050 250 1200 150 1350 200L1440 200L1440 600L0 600L0 200Z"
            fill="currentColor"
          />
        </svg>
        <svg
          className="absolute bottom-0 right-0 w-1/2 h-full text-blue-600 opacity-20"
          viewBox="0 0 1440 600"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 400C150 350 300 450 450 400C600 350 750 450 900 400C1050 350 1200 450 1350 400L1440 400L1440 600L0 600L0 400Z"
            fill="currentColor"
          />
        </svg>
      </div>

      <div className="container mx-auto px-6 text-center relative z-10">
        <h2 className="text-white text-4xl lg:text-5xl font-bold mb-12 font-varela">
          Inside Stories from Our Experts
        </h2>
        <hr className="border-t-2 border-blue-300 mx-auto my-6 w-1/4" />
        <div className="flex flex-wrap gap-12 justify-center">
          {testimonialsData.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

const TestimonialCard = ({ testimonial }) => (
  <div className="employee-div bg-gradient-to-br from-blue-800 via-blue-700 to-blue-600 rounded-2xl p-8 w-full max-w-md text-center shadow-3xl transition-transform transform hover:scale-105 hover:shadow-4xl">
    <img
      src={testimonial.image}
      alt={testimonial.name}
      className="w-32 h-32 rounded-full border-4 border-blue-400 mb-4 mx-auto shadow-md"
    />
    <h3 className="text-white text-2xl font-semibold mb-2 font-varela">
      {testimonial.name}
    </h3>
    <p className="text-white italic font-varela">{testimonial.quote}</p>
  </div>
);

const testimonialsData = [
  {
    image: "./images/1.jpg",
    name: "John Doe",
    quote:
      '"Working at Techmertia has been an incredible experience. The environment here is both innovative and supportive, with a strong emphasis on cutting-edge technology and creative problem-solving. The team is passionate and collaborative, making every project an exciting challenge. It’s a fantastic place to grow professionally while working on groundbreaking AR/VR solutions."',
  },
  {
    image: "./images/cindy.jpg",
    name: "Cindy Smith",
    quote:
      '"Being part of Techmertia has been a game-changer for my career. The company is committed to fostering professional growth and provides ample opportunities for learning and development. The projects we work on are at the forefront of technology, and the supportive team environment makes every day a chance to advance my skills and contribute to groundbreaking innovations."',
  },
  {
    image: "./images/istockphoto-1337144146-612x612.jpg",
    name: "Mark Lee",
    quote:
      '"Techmertia is more than just a workplace; it’s a vibrant community of forward-thinkers. The company culture is collaborative and inclusive, with a strong focus on creativity and teamwork. The enthusiasm for exploring new technologies and the camaraderie among colleagues create an inspiring atmosphere. It’s a place where you feel valued and excited to come in each day."',
  },
];

export default Testimonials;
