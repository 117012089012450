import React, { useState, useEffect } from "react";
import "../css/flipCard.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import JobPostDetails from "./JobPostsDetails";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

export default function CareerOpportunities() {

  const navigate = useNavigate();

  const jobPostings = [
    {
      title: "Full Stack Developer Intern",
      img: "./images/futurism-perspective-digital-nomads-lifestyle(1).jpg",
      requirements: [
        "Pursuing or recently completed a degree in Computer Science or related field.",
        "Basic knowledge of front-end (HTML, CSS, JavaScript) and back-end (Node.js, Python, Java) technologies.",
        "Familiarity with databases (MongoDB, MySQL, PostgreSQL).",
        "Strong problem-solving skills and eagerness to learn.",
        "Effective team player with clear communication skills.",
      ],
      location: "Pune | Remote Opportunity Available",
      employmentType: "Full Time",
      duration: "6 Months",
      responsibilites: [
        "Assist in the development of scalable and efficient web applications.",
        "Work on both front-end and back-end tasks, contributing to all aspects of the development process.",
        "Collaborate with cross-functional teams, including UI/UX designers and senior developers, to build high-quality user experiences.",
        "Write, test, and debug clean and efficient code for both the client and server sides.",
        "Participate in code reviews and help optimize application performance.",
        "Stay updated with the latest web technologies and frameworks.",
      ],
      niceHaving: [
        "Experience with version control systems like Git.",
        "Knowledge of cloud services (AWS, Google Cloud, or Azure) is a plus.",
        "Understanding of RESTful APIs and web security best practices.",
        "Prior experience with personal or academic full-stack projects.",
      ],
      youGain: [
        "Hands-on experience working on both front-end and back-end development.",
        "Mentorship from experienced developers in full-stack technologies.",
        "Opportunity to enhance your portfolio with real-world projects.",
        "Internship certificate and letter of recommendation.",
        "Potential for full-time employment upon successful completion of the internship.",
      ],
      alternateApply:
        "If you're passionate about web development and eager to dive into full-stack development, send your resume and portfolio to",
    },
    {
      title: "Unity Developer Intern",
      description: "Design intuitive user experiences.",
      img: "./images/digital-art-style-illustration-fashion-designer(1).jpg",
      requirements: [
        "Currently pursuing or recently completed a degree in Computer Science, Game Development, or related field.",
        "Basic knowledge of Unity and C# programming.",
        "Understanding of game design principles and experience with 2D/3D game development.",
        "Strong problem-solving skills and a proactive approach to learning.",
        "Ability to work well in a collaborative team environment.",
        "Passionate about games and interactive applications.",
      ],
      location: "Pune | Remote Opportunity Available",
      employmentType: "Full Time",
      duration: "6 Months",
      responsibilites: [
        "Assist in the development of interactive 2D/3D games and simulations using Unity.",
        "Collaborate with designers and senior developers to implement gameplay features and mechanics.",
        "Write efficient, clean, and well-documented code.",
        "Participate in debugging, performance optimization, and testing of game projects.",
        "Help integrate assets such as animations, sounds, and UI elements.",
        "Stay up to date with the latest Unity features and development practices.",
      ],
      niceHaving: [
        "Experience with VR/AR development in Unity.",
        "Familiarity with version control systems like Git.",
        "Knowledge of mobile game development or multiplayer systems.",
        "Personal or academic game development projects to showcase.",
      ],
      youGain: [
        "Practical experience working on real-world Unity projects.",
        "Mentorship from experienced Unity developers.",
        "A chance to develop your portfolio and enhance your technical skills.",
        "Opportunities to contribute to various stages of game development.",
        "Internship certificate and letter of recommendation.",
        "Potential for full-time employment after successful completion of the internship.",
      ],
      alternateApply:
        "If you are excited to build a career in game development and want to gain hands-on experience, apply by sending your resume and portfolio to",
    },
    {
      title: "Android & iOS App Development Intern",
      img: "./images/person-using-ar-technology-perform-their-occupation(1).jpg",
      requirements: [
        "Currently pursuing or recently completed a degree in Computer Science, Software Engineering, or a related field.",
        "Basic knowledge of Android development (Java/Kotlin) and iOS development (Swift/Objective-C).",
        "Familiarity with mobile UI/UX design principles.",
        "Eagerness to learn and adapt to new technologies and frameworks.",
        "Strong problem-solving skills and attention to detail.",
        "Ability to work in a team-oriented environment and communicate effectively.",
      ],
      location: "Pune | Remote Opportunity Available",
      employmentType: "Full Time",
      duration: "6 Months",
      responsibilites: [
        "Assist in the design, development, and testing of mobile applications for Android and iOS platforms.",
        "Collaborate with senior developers to implement new features and optimize existing functionalities.",
        "Work closely with the UI/UX team to ensure apps are intuitive and user-friendly.",
        "Participate in code reviews and contribute to maintaining clean and efficient codebases.",
        "Troubleshoot and debug issues as they arise.",
        "Stay updated with the latest mobile development trends and best practices.",
      ],
      niceHaving: [
        "Experience with mobile cross-platform frameworks like Flutter or React Native.",
        "Knowledge of RESTful APIs and third-party libraries.",
        "Any prior experience with mobile app development (personal or academic projects).",
      ],
      youGain: [
        "Hands-on experience working on real-world projects.",
        "Mentorship from experienced developers and engineers.",
        "A chance to enhance your skills in Android and iOS app development.",
        "Potential for full-time employment after successful completion of the internship.",
        "Internship certificate and letter of recommendation.",
      ],
      alternateApply:
        "If you are excited to kickstart your career in mobile app development and work on meaningful projects, send your resume and any project portfolios to",
    },
    {
      title: "UI/UX Designer Intern",
      img: "./images/scene-with-business-person-working-futuristic-office-job (1).jpg",
      requirements: [
        "Currently pursuing or recently completed a degree in Graphic Design, UI/UX Design, Human-Computer Interaction, or related field.",
        "Basic knowledge of design tools such as Adobe XD, Figma, Sketch, or similar platforms.",
        "Understanding of design principles, typography, color theory, and layout techniques.",
        "Familiarity with user research methods and usability testing.",
        "Strong attention to detail, creativity, and problem-solving skills.",
        "Ability to work well in a team environment and effectively communicate design ideas."
      ],
      location: "Pune | Remote Opportunity Available",
      employmentType: "Full Time",
      duration: "6 Months",
      responsibilites: [
        "Assist in creating wireframes, mockups, and prototypes for web and mobile applications.",
        "Collaborate with developers and product managers to ensure seamless integration of designs into the final product.",
        "Conduct user research, gather feedback, and help improve the usability of existing features.",
        "Help design intuitive, visually appealing, and user-centered interfaces.",
        "Stay up-to-date with design trends, tools, and best practices.",
        "Participate in design reviews and contribute to brainstorming sessions for new features.",
      ],
      niceHaving: [
        "Experience with mobile cross-platform frameworks like Flutter or React Native.",
        "Knowledge of RESTful APIs and third-party libraries.",
        "Any prior experience with mobile app development (personal or academic projects).",
      ],
      youGain: [
        "Hands-on experience working on real-world UI/UX design projects.",
        "Mentorship from experienced designers and product teams.",
        "Internship certificate and letter of recommendation.",
        "Potential for full-time employment after successful completion of the internship.",
      ],
      alternateApply:
        "If you’re passionate about design and eager to contribute to building user-centered products, send your resume and portfolio to",
    },
  ];

  useEffect(() => {
    gsap.utils.toArray(".cards").forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: "top 80%",
        onEnter: () => element.classList.add("animate-slideInEllipticTopFwd"),
        onLeaveBack: () =>
          element.classList.remove("animate-slideInEllipticTopFwd"),
      });
    });

    gsap.utils.toArray(".career-text").forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: "top 80%",
        onEnter: () => element.classList.add("animate-trackingInContractBck"),
        onLeaveBack: () =>
          element.classList.remove("animate-trackingInContractBck"),
      });
    });
  }, []);

  return (
    <section className="flex flex-col w-full h-auto bg-gradient-to-b from-indigo-800 via-blue-500 to-blue-300">
      <h6 className="text-white w-full career-text p-3 text-2xl h-auto lg:text-6xl font-varela">
        Available Job Opportunities
      </h6>
      <div className="flex w-full h-auto p-6">
        <p className="text-md sm:text-lg md:text-2xl career-text w-full font-varela text-white">
          Techmertia Pvt Ltd. is at the forefront of technological innovation. Our
          team of dedicated professionals works collaboratively to create
          cutting-edge solutions that push the boundaries of what's possible.
          With a focus on creativity and excellence, we strive to make a
          meaningful impact in the tech industry. Join us and be part of a team
          that values your skills and ideas, and where you can grow and thrive.
        </p>
      </div>

      <div className="flex justify-center items-center w-full h-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full h-full max-w-6xl">
          {jobPostings.map((job, index) => (
            <div
              key={index}
              className="flip-card w-full min-h-[700px] lg:min-h-[500px] sm:w-[400px] lg:w-[500px] mx-auto cards p-4 lg:p-0"
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img
                    src={job.img}
                    alt={job.title}
                    className="flip-card-image"
                  />
                  <div className="flip-card-overlay">
                    <p className="title font-varela text-center text-lg sm:text-xl lg:text-2xl">
                      {job.title}
                    </p>
                  </div>
                </div>
                <div className="flip-card-back">
                  <div className="flip-card-content p-4">
                    <p className="title text-blue-900 font-varela text-center text-lg sm:text-xl lg:text-2xl">
                      {job.title}
                    </p>
                    <div className="flex flex-col items-baseline p-2">
                      <ul className="list-disc text-blue-900 font-varela text-left px-4 text-sm sm:text-md">
                        <li>
                          <span className="font-bold text-blue-900 mr-2 font-varela text-left text-lg">
                            Location
                          </span>
                          {job.location}
                        </li>
                        <li>
                          <span className="font-bold text-blue-900 mr-2 font-varela text-left text-lg">
                            Employment Type
                          </span>
                          {job.employmentType}
                        </li>
                        <li>
                          <span className="font-bold text-blue-900 mr-2 font-varela text-left text-lg">
                            Duration
                          </span>
                          {job.duration}
                        </li>
                      </ul>
                    </div>
                    <div className="flex flex-col items-start p-2">
                      <p className="text-blue-900 w-auto font-varela font-bold">
                        Requirements:
                      </p>
                      <ul className="list-disc text-blue-900 font-varela text-left px-4 text-sm sm:text-md">
                        {job.requirements.map((resp, i) => (
                          <li key={i}>{resp}</li>
                        ))}
                      </ul>
                    </div>
                    <a
                      onClick={() => navigate("/more-details", {state: job})}
                      className="text-md sm:text-lg lg:text-2xl font-varela text-blue-900 mt-auto p-2 cursor-pointer"
                    >
                      See More Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
