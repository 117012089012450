import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-blue-900">
      {/* Desktop Footer */}
      <footer className="hidden lg:flex flex-col justify-evenly items-center h-auto text-white rounded-t-[250px] py-8">
        <div className="flex flex-row justify-around items-center w-[100%] h-auto m-6">
          <div className="flex flex-col w-auto h-auto justify-center items-center">
            <p className="text-3xl font-extrabold mb-4 font-varela text-white tracking-wide">
              Techmertia Pvt Ltd
            </p>
            <p className="text-lg max-w-[320px] font-varela text-white mx-auto leading-relaxed tracking-wide border-t-2 border-white pt-4">
              Transforming experiences with cutting-edge AR/VR solutions,
              blending virtual worlds with reality for immersive, interactive,
              and unforgettable adventures.
            </p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3304.3290291780386!2d74.79119027307958!3d34.086710556575376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDA1JzEyLjQiTiA3NMKwNDcnNDQuOCJF!5e0!3m2!1sen!2sin!4v1727463746901!5m2!1sen!2sin"
            width="600"
            height="300"
            style={{ border: 0 }}
            className="rounded-3xl"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps Location"
          ></iframe>
        </div>

        <div className="flex flex-col justify-center items-center w-[100%] h-auto">
          <div className="flex flex-col w-auto h-auto justify-center items-center">
            <div className="flex flex-row space-x-6 mb-2">
              <a
                href="#"
                className="relative text-white font-varela text-lg transition-all duration-300 after:content-[''] after:absolute after:w-0 after:h-[2px] after:bottom-[-2px] after:left-0 after:bg-blue-400 after:transition-all after:duration-300 hover:after:w-full hover:text-blue-400"
              >
                About
              </a>
              <a
                href="/career"
                className="relative text-white font-varela text-lg transition-all duration-300 after:content-[''] after:absolute after:w-0 after:h-[2px] after:bottom-[-2px] after:left-0 after:bg-blue-400 after:transition-all after:duration-300 hover:after:w-full hover:text-blue-400"
              >
                Careers
              </a>
              <a
                href="#"
                className="relative text-white font-varela text-lg transition-all duration-300 after:content-[''] after:absolute after:w-0 after:h-[2px] after:bottom-[-2px] after:left-0 after:bg-blue-400 after:transition-all after:duration-300 hover:after:w-full hover:text-blue-400"
              >
                Contact
              </a>
            </div>

            <div className="flex flex-row space-x-4">
              <div className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
                <FaInstagram className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
              </div>
              <div className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
                <FaFacebookF className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
              </div>
              <a href = "https://www.linkedin.com/company/105723279/" className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
                <FaLinkedinIn className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
              </a>
              <div className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
                <FaTwitter className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-center items-center w-full h-auto mt-6 space-x-6">
            <div className="flex items-center space-x-4">
              <a
                href="/"
                className="text-lg  font-varela text-gray-300 hover:text-white transition-colors duration-300 cursor-pointer"
              >
                Techmertia Pvt Ltd
              </a>
              <span className="text-gray-400">|</span>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@techmertia.com"
                className="text-lg my-2 font-varela text-gray-300 hover:text-white transition-colors duration-300 cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@techmertia.com
              </a>
              <span className="text-gray-400">|</span>
            </div>
            <div className="flex items-center space-x-4">
              <p className="text-lg  font-varela text-gray-300 hover:text-white transition-colors duration-300">
                Pune, Maharashtra
              </p>
              <span className="text-gray-400">|</span>
            </div>
            <p className="text-lg  font-varela text-gray-300 hover:text-white transition-colors duration-300">
              +91 8899996568
            </p>
          </div>
        </div>
      </footer>

      {/* Mobile Footer */}
      <footer className="lg:hidden flex flex-col justify-center items-center h-auto bg-blue-900 text-white rounded-t-[100px] py-8">
        <div className="flex flex-col w-auto h-auto justify-center items-center p-2">
          <p className="text-3xl font-extrabold mb-4 font-varela text-white tracking-wide">
          Techmertia Pvt Ltd
          </p>
          <p className="text-lg max-w-[320px] font-varela text-white mx-auto leading-relaxed tracking-wide border-t-2 border-white pt-4">
            Transforming experiences with cutting-edge AR/VR solutions, blending
            virtual worlds with reality for immersive, interactive, and
            unforgettable adventures.
          </p>
        </div>
        <div className="flex flex-col my-2 w-auto h-auto justify-center items-center">
          <div className="flex justify-center items-center p-4">
            <iframe
              src="https://www.google.com/maps/place/Marvel+Vista/@18.4858985,73.8858409,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2ea800f350aa9:0x84e4abbebcf0ab1c!8m2!3d18.4858934!4d73.8884158!16s%2Fg%2F124t3n4cc?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"
              width="280"
              height="140"
              style={{ border: 0 }}
              className="rounded-3xl"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Location"
            ></iframe>
          </div>
          <div className="flex flex-row space-x-2 mb-6">
            <a
              href="#"
              className="relative text-white font-varela text-lg transition-all duration-300 after:content-[''] after:absolute after:w-0 after:h-[2px] after:bottom-[-2px] after:left-0 after:bg-blue-400 after:transition-all after:duration-300 hover:after:w-full hover:text-blue-400"
            >
              About
            </a>
            <a
              href="/career"
              className="relative text-white font-varela text-lg transition-all duration-300 after:content-[''] after:absolute after:w-0 after:h-[2px] after:bottom-[-2px] after:left-0 after:bg-blue-400 after:transition-all after:duration-300 hover:after:w-full hover:text-blue-400"
            >
              Careers
            </a>
            <a
              href="#"
              className="relative text-white font-varela text-lg transition-all duration-300 after:content-[''] after:absolute after:w-0 after:h-[2px] after:bottom-[-2px] after:left-0 after:bg-blue-400 after:transition-all after:duration-300 hover:after:w-full hover:text-blue-400"
            >
              Contact
            </a>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
              <FaInstagram className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
            </div>
            <div className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
              <FaFacebookF className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
            </div>
            <a href = "https://www.linkedin.com/company/105723279/" className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
              <FaLinkedinIn className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
            </a>
            <div className="group flex justify-center items-center rounded-full bg-gradient-to-r from-blue-900 to-blue-700 p-3 transition-colors duration-300 hover:shadow-xl hover:shadow-blue-400/50">
              <FaTwitter className="text-2xl text-white transition-transform duration-300 transform group-hover:scale-110 group-hover:animate-bounceSmooth" />
            </div>
          </div>
        </div>

        <div className="flex flex-col my-2 justify-center items-center">
          <a
            href="/"
            className="text-lg my-2  font-varela text-gray-300 hover:text-white transition-colors duration-300 cursor-pointer"
          >
            Techmertia Pvt Ltd
          </a>
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@techmertia.com"
            className="text-lg my-2 font-varela text-gray-300 hover:text-white transition-colors duration-300 cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@techmertia.com
          </a>

          <p className="text-lg  my-2 font-varela text-gray-300 hover:text-white transition-colors duration-300">
           Pune, Maharashtra 
          </p>
          <p className="text-lg  my-2 font-varela text-gray-300 hover:text-white transition-colors duration-300">
            +91 8899996568
          </p>
        </div>
      </footer>

      <p className="flex justify-center items-center bg-blue-900 py-2 px-1 font-varela text-white">
        &copy; 2024 || All Rights Reserved by Techmertia Pvt Ltd
      </p>
    </div>
  );
};

export default Footer;
