import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const CareersIntro = () => {

  useEffect(() => {
    const animateElements = (selector, animationClass, triggerOptions = {}) => {
      gsap.utils.toArray(selector).forEach((element) => {
        ScrollTrigger.create({
          trigger: element,
          start: triggerOptions.start || 'top 80%',
          onEnter: () => element.classList.add(animationClass),
          onLeaveBack: () => element.classList.remove(animationClass),
          ...triggerOptions
        });
      });
    };

    // Animations for text and images
    animateElements('.culture-text', 'animate-trackingInContractBckBottom');
    animateElements('.img', 'animate-flipDiagonal2Bck');
    
  }, []);

  return (
    <div className="flex flex-col w-full h-auto bg-gradient-to-b from-indigo-800 via-blue-500 to-blue-300">
      {/* Header Section */}
      <header className="text-center py-10 animate-textFlickerInGlow">
        <h6 className="text-white w-full p-3 intro-text text-2xl lg:text-6xl font-varela text-left">
          Careers
        </h6>
        <p className="text-lg md:text-2xl font-varela animate-textFlickerInGlow text-left w-11/12 p-4 text-white">
          At Techmertia, we're passionate about innovation and creativity. We're on the lookout for talented professionals to join our dynamic team and contribute to exciting projects in the tech world. Explore our current job openings to find your perfect role and become a part of our mission to drive technological advancements and deliver exceptional solutions. Apply now to start your journey with us and help shape the future of technology!
        </p>
      </header>

      {/* About Section */}
      <div className="flex flex-col w-full items-center h-auto p-2">
        <div className="flex flex-col justify-center items-center w-4/5">
          <h6 className="text-white text-2xl lg:text-5xl font-varela text-left p-3 animate-textFocusIn">
            About Our Company
          </h6>
          <div className="flex flex-col lg:flex-row items-center">
            <img
              src="./images/view-building-with-cartoon-style-architecture-removebg-preview.png"
              className="w-72 lg:w-96 rounded-xl animate-textFocusIn"
              alt="Company"
            />
            <p className="lg:text-2xl text-white font-varela animate-textFocusIn">
            Techmertia is at the forefront of technological innovation. Our team works collaboratively to create cutting-edge solutions. Join us and be part of a team that values your skills and ideas, where you can grow and thrive.
            </p>
          </div>
        </div>
      </div>

      {/* Company Culture Section */}
      <section className="relative py-16 overflow-hidden">
        <div className="absolute top-0 left-0 -z-10 w-full h-full pointer-events-none">
          <svg className="absolute -top-16 -left-16 w-1/2 h-auto text-blue-200 opacity-30" viewBox="0 0 1440 800" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="200" cy="200" r="200" stroke="currentColor" strokeWidth="100" />
            <circle cx="1200" cy="600" r="250" stroke="currentColor" strokeWidth="100" />
          </svg>
        </div>

        <div className="container mx-auto px-6">
          <h2 className="text-4xl lg:text-5xl font-bold font-varela text-white text-center mb-12">
            Our Company Culture
          </h2>
          <div className="flex flex-col lg:flex-row items-center gap-12 lg:gap-20">
            <div className="relative flex-1 bg-white p-10 lg:p-16 rounded-3xl shadow-lg">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-blue-100 to-transparent opacity-70 rounded-3xl"></div>
              <div className="relative z-10">
                <h3 className="text-3xl lg:text-4xl font-varela font-semibold text-blue-900 mb-6 culture-text">
                  A Culture of Innovation and Collaboration
                </h3>
                <p className="text-lg lg:text-xl text-gray-800 font-varela mb-4 leading-relaxed culture-text">
                  At Techmertia, our company culture is built on the pillars of innovation, collaboration, and inclusivity. We believe in fostering a supportive environment where every team member's ideas are valued and encouraged.
                </p>
                <p className="text-lg lg:text-xl text-gray-800 font-varela leading-relaxed culture-text">
                  We celebrate diversity and strive to create a workplace where creativity and individuality are embraced. Join us to be part of a team that not only works together but also grows together.
                </p>
              </div>
            </div>

            {/* Image */}
            <div className="relative flex-1 max-w-md lg:max-w-lg">
              <img
                src="./images/corp.jpg"
                alt="Company Culture"
                className="w-full h-auto rounded-3xl shadow-xl transform transition-transform duration-700 hover:scale-105"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CareersIntro;
