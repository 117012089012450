import React, { useState, useEffect } from "react";
import "../css/future.css";
import {
  FaTimes,
  FaFileUpload,
  FaRegClipboard,
  FaEnvelopeOpenText,
  FaCheckCircle,
} from "react-icons/fa";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const processSteps = [
  {
    icon: FaRegClipboard,
    title: "1. Submit Your Details",
    description: "Share your resume, portfolio, and other information with us.",
  },
  {
    icon: FaFileUpload,
    title: "2. Resume Review",
    description:
      "Our team reviews your submission to find the best fit for future roles.",
  },
  {
    icon: FaEnvelopeOpenText,
    title: "3. Stay in Touch",
    description:
      "If opportunities arise, we’ll reach out to you for interviews or discussions.",
  },
  {
    icon: FaCheckCircle,
    title: "4. Join Our Team",
    description:
      "When the right opportunity comes along, you’ll be the first to know!",
  },
];

const FutureOpportunities = () => {
  useEffect(() => {
    const animations = [
      { target: ".process-box", animation: "animate-bounceInTop" },
      { target: ".process-button", animation: "animate-shakeHorizontal" },
      { target: ".process-text", animation: "animate-trackingInContractBck" },
    ];

    animations.forEach(({ target, animation }) => {
      gsap.utils.toArray(target).forEach((element) => {
        ScrollTrigger.create({
          trigger: element,
          start: "top 80%",
          onEnter: () => element.classList.add(animation),
          onLeaveBack: () => element.classList.remove(animation),
          stagger: 0.4,
        });
      });
    });
  }, []);

  return (
    <div className="flex flex-col w-full bg-gradient-to-b from-blue-300 via-blue-500 to-indigo-800">
      {/* Header Section */}
      <header className="text-center py-10">
        <h6 className="text-white w-full p-3 process-text text-2xl box text-left lg:text-6xl font-varela">
          Apply For Future Opportunities
        </h6>
        <p className="text-lg md:text-2xl process-text font-varela text-left w-[95%] p-4 text-white">
          Passionate about what you do? So are we! At Techmertia, we believe
          in nurturing exceptional talent and driving innovation. Even if our
          current openings don’t match your skills, we want to hear from you!
          Share your details and stay connected—your dream job might just be
          around the corner.
          <br /> Let’s shape the future together!
        </p>
      </header>

      {/* Application Process */}
      <div className="flex flex-col items-center w-full mb-8">
        <h6 className="text-white text-xl lg:text-3xl font-varela text-center mb-6">
          Our Application Process
        </h6>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-6xl">
          {processSteps.map((step, index) => (
            <li
              key={index}
              className="flex flex-col items-center bg-white bg-opacity-10 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 process-box"
            >
              <step.icon className="text-4xl mb-3 text-white" />
              <h6 className="text-lg font-bold text-white">{step.title}</h6>
              <p className="text-sm text-center text-white mt-2">
                {step.description}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {/* Apply Now Button */}
      <div className="w-[100%] h-auto flex flex-row justify-center items-center my-6">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://forms.gle/hHomW4Ks5soLLhKG9"
          className="text-white font-bold font-varela text-xl process-button rounded-lg"
        >
          Apply Now
        </a>
      </div>
    </div>
  );
};

export default FutureOpportunities;
